import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { PageEvent } from "@angular/material/paginator";
import { iApplicantListModel, iApplicantModel, iCandidateFindModel } from "src/app/shared/models/applicant-candidate.model";
import { EmployerOnboardingService } from "../../../employer-root/employer-root.service";
import { DialogApplicantDetailsComponent } from "../../details/root/dialog-applicant-details/dialog-applicant-details.component";
import { Input } from "@angular/core";
import { LoggerService as Logger } from "src/app/shared/services/logger.service";
import { eFindCandidateTabs } from "src/app/shared/enums/employer.enums";
import { iCandidateIdSubmissionOptional, iTabDetails } from "../../../employer-root/employer.models";
import {
  DisplayContentType,
  SideDrawerRootComponent,
} from "src/app/shared/components/side-drawer-root/side-drawer-root.component";
import { sideDrawerEvents } from "src/app/shared/enums/sideDrawerEvents.enums";
import { MtxDrawer } from "@ng-matero/extensions/drawer";
import { FilterOption, FilterParameter, iFieldMultiSelect, iFieldMultiSelectOutPut } from "src/app/shared/models/filter.model";
import { UserService } from "src/app/user/user-root/user-root.service";
import { iEmployerJobModel, iJobCardModel } from "src/app/shared/models/job.model";
import { OnboardingRootService } from "src/app/user/onboarding/onboarding-root/onboarding-root.service";

@Component({
  selector: "app-search-candidates-pane",
  templateUrl: "./search-candidates-pane.component.html",
  styleUrls: ["./search-candidates-pane.component.scss"],
})
export class SearchCandidatesPaneComponent implements OnInit, OnChanges {
  // searchTabs = [
  //   { value: "Search", label: "Search", emptyMsg: "" },
  //   { value: "Followed", label: "Followed By", emptyMsg: "" },
  //   { value: "Saved", label: "Saved", emptyMsg: "" },
  // ];
  selectedMenuTab = new FormControl(this._service.applicantSearchChildTab);
  @Input() isLoading: boolean = true;
  @Input() isSearching: boolean = false;
  @Input() queriedCandidates: iCandidateFindModel;
  @Input() savedCandidates: iApplicantListModel[];
  @Input() querySharedCandidate: iCandidateFindModel;
  @Input() followedByCandidates: iApplicantListModel[];
  @Output() pagedEvent = new EventEmitter<PagedEventCandidates>();
  @Output() saveCandidateId = new EventEmitter<string>();
  @Output() filterCandidates = new EventEmitter<iFieldMultiSelectOutPut>();
  keywordQuery: string;
  locationQuery: string;
  previousPageIndex: number = -1;
  isPriortized: boolean = false;
  fullParam: string = "";
  allFields: FilterParameter[] = [];
  selectedAutoPopulateCategories: FilterParameter = undefined;


  constructor(
    private _service: EmployerOnboardingService,
    public dialog: MatDialog,
    private drawer: MtxDrawer,
    private _usrService: UserService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.querySharedCandidate && "querySharedCandidate" in changes) {
      this.queriedCandidates = this.querySharedCandidate;
    }
  }

  ngOnInit(): void {
    if (!this._service.bullhornData.savedCandidateIds) {
      this._service.bullhornData.savedCandidateIds = [];
    }

  
    //this.setSavedCandidates();

    //Update saved list when tab changes (or on toggle)
    this.selectedMenuTab.valueChanges.subscribe((x) => {
      //this.setSavedCandidates();
    });
  }

  getAllSelectedValuesPerJobs(): FilterParameter[] {
    let allJobs : FilterParameter[] = [];
    for (let job of this._service._getAllJobs) {
      let jobParameter: FilterParameter = { category: "", filters: [], selectedCount: 0};
      jobParameter.category = job.title;
      let selectedFields = this._service.createSelectedFieldBasedOnJob(job);
      jobParameter.selectedCount = selectedFields?.length;
      jobParameter.filters = selectedFields;
      allJobs.push(jobParameter);
    }
    return allJobs;
  }


  private get _getCompanySavedCandidates(): string[] {
    return this._service.bullhornData.savedCandidateIds ? this._service.bullhornData.savedCandidateIds : [];
  }
  get _getTabs(): iTabDetails[] {
    return this._service.candidateTabs;
  }

  get _getListByTab(): iApplicantListModel[] {
    switch (this.selectedMenuTab.value) {
      case eFindCandidateTabs.Saved:
        return this.savedCandidates;
      case eFindCandidateTabs.Followed:
        return this.followedByCandidates;
      case eFindCandidateTabs.Search:
      default:
        return this.queriedCandidates.candidates;
    }
  }

  // private setSavedCandidates() : void {
  //   this.filteredSavedCandidates.splice(0);
  //   this.queriedCandidates?.candidates
  //       .map((candidate: iApplicantModel) =>
  //         ((this._getCompanySavedCandidates.includes(candidate.id))
  //           ? this.filteredSavedCandidates.push(candidate)
  //           : this.queriedCandidates?.candidates.slice()));
  // }

  toggleSaveCandidate(id: string) {
    this.saveCandidateId.emit(id);
  }

  get _hasQueriedResults(): boolean {
    return this.queriedCandidates?.candidates?.length >= 1;
  }

  get _hasSavedCandidates(): boolean {
    return this.savedCandidates?.length > 0;
  }

  get _getSavedCandidates(): iApplicantListModel[] {
    return this.savedCandidates?.length > 0 ? this.savedCandidates : [];
  }

  get _hasFollowingCandidates(): boolean {
    return this.followedByCandidates?.length > 0;
  }

  get _isSearching(): boolean {
    if (this.isSearching && this._service.applicantSearchChildTab !== eFindCandidateTabs.Search) {
      this._service.applicantSearchChildTab = eFindCandidateTabs.Search;
      this.selectedMenuTab.setValue(eFindCandidateTabs.Search);
    }

    return this.isSearching;
  }

  get _isViewingSearchTab(): boolean {
    return this.selectedMenuTab.value == eFindCandidateTabs.Search;
  }
  get _isViewingSavedTab(): boolean {
    return this.selectedMenuTab.value == eFindCandidateTabs.Saved;
  }
  get _isViewingFollowTab(): boolean {
    return this.selectedMenuTab.value == eFindCandidateTabs.Followed;
  }

  _setCandidateSearchMenuTabIndex(idx: number): void {
    this.selectedMenuTab.setValue(idx);
    this._service.applicantSearchChildTab = idx;
  }

  getSavedCandidates() {
    if (this._service.bullhornData.savedCandidateIds?.length > 0) {
      //TODO: query Bullhorn and pass list of ids
    }
  }

  get _getPageSize(): number {
    return this.queriedCandidates.pageSize;
  }
  get _getPageIndex(): number {
    return this.queriedCandidates.pageIndex;
  }
  get _getTotalSize(): number {
    return this.queriedCandidates.totalCandidates;
  }

  //#region Demo https://v15.material.angular.io/components/paginator/examples
  _pageIndex = 0;
  _pageSizeOptions = [20]; //not currently being used - backend always returns 20 for now.
  _showPageSizeOptions = false;
  _hidePageSize = true;
  _showFirstLastButtons = true;
  pageEvent: PageEvent;
  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.previousPageIndex = e.previousPageIndex;
    this.queriedCandidates.pageIndex = e.pageIndex;
    this.queriedCandidates.pageSize = e.pageSize;
    this._pageIndex = e.pageIndex;
    const event: PagedEventCandidates = { pageEvent: e, candidateFilters: this._service.selectedCandidateFiltersOutput.selectedFields, selectedJob: this._service.selectedCandidateFiltersOutput.selectedAutoPopulateField };
    this._service.employerRootSideNavContent.next(this._service.nextPage);
    this.pagedEvent.emit(event);
    Logger.debug("handlePageEvent", e);
  }
  //#endregion Demo

  getAllStates(): FilterOption[] {
    const fullNames = this._usrService.getAllStateNames();
    const abbreviatedNames = this._usrService.getAllStateAbbreviations();
    let allStates: FilterOption[] = [];
    for (let i = 0; i < fullNames.length; i++) {
      allStates.push({ label: fullNames[i], value: abbreviatedNames[i] });
    }
    return allStates;
  }

  initLocationCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.Location,
      filters: [
        {
          label: eLocationFilters.LocationPreference,
          data: [
            { label: "Remote", value: "remote" },
            { label: "Hybrid", value: "hybrid" },
            { label: "Onsite", value: "onsite" },
          ],
        },
        { label: eLocationFilters.State, data: this.getAllStates() }, // add all 50 states
        { label: eLocationFilters.City, data: [] }, // add all relevant cities
        { label: eLocationFilters.Zip, data: [] },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }


  initEmploymentTypeCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.EmploymentType,
      filters: [
        {
          label: eEmploymentTypeFilters.EmploymentType,
          data: [
            { label: "Full-Time", value: "Full-Time (40 Hours a Week)" },
            { label: "Part-Time", value: "Part-Time (10-30 Hours a Week)" },
            { label: "Contract/Project", value: "Contract/Project Based Work" },
          ],
        },
        {
          label: eEmploymentTypeFilters.WorkingHours,
          data: [
            { label: "Standard Hours", value: "Standard" },
            { label: "School Friendly Hours", value: "School" },
            { label: "Flexible Hours", value: "Flexible" },
            { label: "Prefers To Set Own Time", value: "Own Time" },
          ],
        }, // add all relevant cities
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initExperienceCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.Experience,
      filters: [
        {
          label: eExperienceFilters.Experience,
          data: [
            { label: "Entry Level (0-1 years)", value: "1" },
            { label: "Associate (1-4 years)", value: "2" },
            { label: "Mid Level (5-8 years)", value: "3" },
            { label: "Senior Level (8+ years)", value: "4" },
          ],
        },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initCompensationCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.Compensation,
      filters: [
        {
          label: eCompensationFilters.CompensationType,
          data: [
            { label: eCompensationFilters.Salary, value: "salary" },
            { label: eCompensationFilters.Hourly, value: "hourly" },
            { label: eCompensationFilters.Contract, value: "contract" },
          ],
        },
        {
          label: eCompensationFilters.Salary,
          data: [
            { label: "$25,000-$50,000", value: "1" },
            { label: "$50,000-$75,000", value: "2" },
            { label: "$75,000-$100,000", value: "3" },
            { label: "$100,000-$150,000", value: "4" },
            { label: "$150,000-$200,000", value: "5" },
            { label: "$200,000+", value: "6" },
          ],
        },
        {
          label: eCompensationFilters.Hourly,
          data: [
            { label: "$15-$25", value: "1" },
            { label: "$26-$35", value: "2" },
            { label: "$36-$50", value: "3" },
            { label: "$51-$75", value: "4" },
            { label: "$76-$100", value: "5" },
            { label: "100+", value: "6" },
          ],
        },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  initJobTypeCategory(): FilterParameter {
    let filterCategory: FilterParameter = {
      category: eFilterCategoryTitle.JobCategory,
      filters: [
        {
          label: eJobSkillsCategoryFilters.JobSkillsCategory,
          data: [
            { label: "Administrative", value: "Administrative Skills Focus" },
            { label: "Business", value: "Business Skills Focus" },
            { label: "Creative", value: "Creative Skills Focus" },
            { label: "Financial", value: "Financial Skills Focus" },
            { label: "Legal", value: "Legal Skills Focus" },
            { label: "Marketing", value: "Marketing Skills Focus" },
            { label: "Operations", value: "Operations Skills Focus" },
            { label: "People", value: "People Skills Focus" },
            { label: "Project Management", value: "Project Management Skills Focus" },
            { label: "Sales", value: "Sales Skills Focus" },
            { label: "Technical", value: "Technical Skills Focus" },
          ], 
        },
        {
          label: eJobSkillsCategoryFilters.CategorySkills,
          data: this.getAllCategorySkills(),
        },
      ],
      selectedCount: 0,
    };
    return filterCategory;
  }

  getAllCategorySkills(): FilterOption[] {
    let lst = [];
    let value = this._usrService.setSkillData();
    for(let skills of value) {
      for (let skill of skills.skills) {
        lst.push({ label: skill.displayName, value: skill.displayName });
      }
    }
    return lst;
  }

  applyFiltersToUrl() {
    const outPutVal : iFieldMultiSelectOutPut = {selectedFields: this._service.selectedCandidateFiltersOutput.selectedFields, isPrioritized: this.isPriortized, allFields: this.allFields, selectedAutoPopulateField: this.selectedAutoPopulateCategories};
    this.filterCandidates.emit(outPutVal);
  }

  open() {
    this.getAllCategorySkills();
    const drawerRef = this.drawer.open(SideDrawerRootComponent, {
      width: "380px",
      data: {
        displayContentType: DisplayContentType.CandidateFilter,
        title: "Filter",
        selectedFields: this._service.selectedCandidateFiltersOutput.selectedFields,
        isPriortized: this.isPriortized,
        categories: this.isPriortized ? this.allFields:
        [
          this.initJobTypeCategory(),
          this.initLocationCategory(),
          this.initEmploymentTypeCategory(),
          this.initExperienceCategory(),
          this.initCompensationCategory(),
        ],
        jobSelectedFields: this.getAllSelectedValuesPerJobs(),
        selectedAutoPopulateCategories: this.selectedAutoPopulateCategories,
        primaryBtn: { btnName: "Apply", eventName: sideDrawerEvents.applyCandidateFilter },
        secondaryBtn: { btnName: "Clear", eventName: sideDrawerEvents.clearApplicantFilter },
      },
    });

    drawerRef.afterDismissed().subscribe((result) => {
      if (result?.returnValue) {
        this._service.selectedCandidateFiltersOutput = result?.returnValue;
        this.isPriortized = result?.returnValue.isPrioritized;
        this.allFields = result?.returnValue.allFields;
        this.selectedAutoPopulateCategories = result?.returnValue.selectedAutoPopulateField;
        this.applyFiltersToUrl();
      }
    });
  }

  viewCandidate(candidate: iCandidateIdSubmissionOptional) {
    const dialogRef = this.dialog.open(DialogApplicantDetailsComponent, {
      width: "800px",
      data: {
        candidateList: this._getListByTab,
        selectedId: candidate.candidateId,
        parentcomponent: this,
      },
    });
  }
}

export interface PagedEventCandidates {
  pageEvent: PageEvent;
  candidateFilters: iFieldMultiSelect[];
  selectedJob: FilterParameter;
}

export enum eFilterCategoryTitle {
  JobCategory = "Job Category",
  Location = "Location",
  EmploymentType = "Employment Type",
  Experience = "Experience",
  Compensation = "Compensation"
}

export enum eJobSkillsCategoryFilters {
  JobSkillsCategory = "Job Skills Categories",
  CategorySkills = "Category Skills",
  CustomSkills = "Custom Skills"
}

export enum eLocationFilters {
  LocationPreference = "Location Preference",
  State = "State",
  City = "City",
  Zip = "Zip"
}

export enum eEmploymentTypeFilters {
  EmploymentType = "Employment Type",
  WorkingHours = "Working Hours"
}

export enum eExperienceFilters {
  Experience = "Experience"
}

export enum eCompensationFilters {
  CompensationType = "Compensation Type",
  Salary = "Salary",
  Hourly = "Hourly",
  Contract = "Contract"
}